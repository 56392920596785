import { render, staticRenderFns } from "./promptManagement.vue?vue&type=template&id=a9ba0362&scoped=true"
import script from "./promptManagement.vue?vue&type=script&lang=js"
export * from "./promptManagement.vue?vue&type=script&lang=js"
import style0 from "./promptManagement.vue?vue&type=style&index=0&id=a9ba0362&prod&lang=scss&scoped=advertiseManagement"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9ba0362",
  null
  
)

export default component.exports